import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1fb8f1',
    },
    grey: {
      100: '#f5f5f5',
      400: '#a3a3a3',
      500: '#737373',
      900: '#171717',
    },
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h2: {
      fontFamily: '"Bebas Neue", sans-serif',
      fontSize: '3.75rem',
      lineHeight: 1,
      letterSpacing: '0.025em',
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '1.13rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1.125rem',
    },
    body2: {
      fontSize: '1rem',
    },
  },
});

theme.components = {
  ...theme.components,
  MuiLink: {
    defaultProps: {
      underline: 'hover',
    },
  },
};
