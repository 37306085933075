import React, { ReactNode } from 'react';

import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { ThemeProvider } from '@mui/material';

import { theme } from '../styles/theme';
import { SiteMetadata } from '../types';

export interface LayoutData {
  site: {
    siteMetadata: SiteMetadata;
  };
}

export interface LayoutProps {
  children: ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  const data: LayoutData = useStaticQuery(query);

  return (
    <ThemeProvider theme={theme}>
      <main>
        <Helmet htmlAttributes={{ lang: 'fi' }} title={data.site.siteMetadata.title} />
        {children}
      </main>
    </ThemeProvider>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
